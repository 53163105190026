@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300&family=Lato:wght@400&family=Poppins:wght@100;400;500&family=Space+Mono&display=swap');

@font-face {
    font-family: Graphik;
    src: url('./fonts/Graphik-Semibold-Web.woff2') format('woff2'),
    url('./fonts/Graphik-Semibold-Web.woff') format('woff');
    font-weight:  600;
    font-style:   normal;
    font-stretch: normal;
}

@font-face {
    font-family: Graphik;
    src: url('./fonts/Graphik-Medium-Web.woff2') format('woff2'),
    url('./fonts/Graphik-Medium-Web.woff') format('woff');
    font-weight:  500;
    font-style:   normal;
    font-stretch: normal;
}

@font-face {
    font-family: Graphik;
    src: url('./fonts/Graphik-Regular-Web.woff2') format('woff2'),
    url('./fonts/Graphik-Regular-Web.woff') format('woff');
    font-weight:  400;
    font-style:   normal;
    font-stretch: normal;
}

@font-face {
    font-family: Graphik;
    src: url('./fonts/Graphik-RegularItalic-Web.woff2') format('woff2'),
    url('./fonts/Graphik-RegularItalic-Web.woff') format('woff');
    font-weight:  400;
    font-style:   italic;
    font-stretch: normal;
}

@font-face {
    font-family: Graphik;
    src: url('./fonts/Graphik-Light-Web.woff2') format('woff2'),
    url('./fonts/Graphik-Light-Web.woff') format('woff');
    font-weight:  300;
    font-style:   normal;
    font-stretch: normal;
}

@font-face {
    font-family: Graphik;
    src: url('./fonts/Graphik-LightItalic-Web.woff2') format('woff2'),
    url('./fonts/Graphik-LightItalic-Web.woff') format('woff');
    font-weight:  300;
    font-style:   italic;
    font-stretch: normal;
}

@font-face {
    font-family: Graphik;
    src: url('./fonts/Graphik-Extralight-Web.woff2') format('woff2'),
    url('./fonts/Graphik-Extralight-Web.woff') format('woff');
    font-weight:  200;
    font-style:   normal;
    font-stretch: normal;
}

@font-face {
    font-family: Graphik;
    src: url('./fonts/Graphik-ExtralightItalic-Web.woff2') format('woff2'),
    url('./fonts/Graphik-ExtralightItalic-Web.woff') format('woff');
    font-weight:  200;
    font-style:   italic;
    font-stretch: normal;
}

