.hide-legend legend {
  display: none;
}

.tighter-padding .MuiOutlinedInput-input {
  padding: 10px 32px 12px 12px;
}

.select-width {
  min-width: 135px;
}

.select-width .MuiSelect-root {
  text-align: left;
}